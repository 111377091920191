<template>
    <div class="st-data-table">
        <b-table-simple
            fixed
            hover
        >
            <b-thead class="st-data-table-head">
                <b-tr class="st-data-table-head-row">
                    <b-th class="st-data-table-drag-column"></b-th>
                    <b-th class="text-uppercase st-header-text">{{fields.name.label}}</b-th>
                    <b-th class="text-uppercase st-header-text">{{fields.key.label}}</b-th>
                    <b-th class="text-uppercase st-header-text">{{fields.next_step.label}}</b-th>
                    <b-th class="text-uppercase st-header-text">{{$t('PROCESS_EDITOR.TABLE_HEADER.TRANSITION')}}</b-th>
                    <b-th class="text-uppercase st-header-text">{{fields.conditional_routing.label}}</b-th>
                    <b-th class="text-uppercase st-header-text">{{$t('PROCESS_EDITOR.TABLE_HEADER.ACTIONS')}}</b-th>
                </b-tr>
            </b-thead>

            <draggable
                :class="{[`cursor-grabbing`]: dragging === true}"
                class="st-data-table-body"
                v-model="stepList" group="stepList" @start="dragging = true"
                @end="dragging = false"
                @change="dragChanged"
                tag="tbody"
                :sort="false"
                handle=".handle"
                draggable=".changeable"
            >
                <b-tr v-for="step in stepList"
                    :key="step.id"
                    class="st-data-table-body-row"
                    :class="[{ 'changeable': !step.fixed  }, { 'text-primary': step.fixed  }]"
                >
                    <b-td>
                        <div class="handle">
                            <b-icon font-scale="1" icon="list" />
                        </div>
                    </b-td>
                    <b-td>{{step.name}}</b-td>
                    <b-td>{{step.action_name}}</b-td>
                    <b-td>{{step.next_step}}</b-td>
                    <b-td>
                        <b-button
                            class="btn btn-icon btn-light btn-hover-primary st-btn-data-table"
                            @click="doTransition(action, data)"
                            v-b-tooltip.hover="{ variant: 'info' }"
                            title="transition"
                            disabled
                        >
                            <span class="st-data-table-icon svg-icon svg-icon-md  svg-icon-primary">
                                <i class="fas fa-plus"></i>
                            </span>
                        </b-button>
                    </b-td>
                    <b-td>
                        {{step.conditional_routing}}
                    </b-td>
                    <b-td v-if="actions && actions.length > 0">
                        <span v-for="(action,i) in actions" v-bind:key="i">
                            <span v-if="action.permission">
                                <b-button
                                    :class="`btn btn-icon btn-light btn-hover-${action.type} st-btn-data-table`"
                                    @click="doAction(action.name, step)"
                                    v-b-tooltip.hover="{ variant: 'info' }"
                                    :title="action.tooltipText"
                                    :disabled="step.fixed"
                                >
                                    <span :class="`st-data-table-icon svg-icon svg-icon-md  svg-icon-${action.type}`">
                                        <span v-if="action.customIcon" v-html="action.icon"></span>
                                        <i v-else :class="`fas fa-${action.icon}`"></i>
                                    </span>
                                </b-button>
                            </span>
                        </span>
                    </b-td>
                </b-tr>
            </draggable>
        </b-table-simple>
        <div class="st-pagination">
            <div v-if="steps && steps.length" class="d-flex align-items-center justify-content-between">
                <div class="text-dark-50">
                    {{ displayText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ProcessStageModel } from '@/modules/process-editor/models/process-stage-model';

const { fields } = ProcessStageModel;

import draggable from 'vuedraggable'
export default {
    name: 'ProcessStageTable',
    components: { draggable},
    props: {
        steps: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            dragging: false,
            fields,
            stepList: this.steps.map((step)=> ({ ...step,  fixed: step.order === 0 || step.order === 999999 })),

        };
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            processEditorPermissions: 'processEditor/processEditorPermissions',
        }),
        isLoading() {
            return this.loading['processEditor/getSteps'];
        },
        displayText() {
            return this.$t('GENERAL.PAGINATION', { start: 1, end: this.steps.length });
        },
        actions() {
            return [
                {
                    name: 'edit',
                    icon: 'edit',
                    tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                    customIcon: false,
                    type: 'primary',
                    permission: this.processEditorPermissions.hasPermissionToEditSteps
                },
                {
                    name: 'delete',
                    tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                    icon: 'trash-alt',
                    customIcon: false,
                    type: 'danger',
                    permission: this.processEditorPermissions.hasPermissionToDestroyStep
                },
            ]
        }
    },
    methods: {
        ...mapActions({
            doRemove:'processEditor/stageForm/remove',
        }),
        dragChanged(eventData) {
            if (eventData.moved) {
                this.$emit('listUpdated');
            }
        },
        doAction(action, data) {
            if (action === 'edit') {
                this.$emit('editStep', data);
            }
            if (action === 'delete') {
                this.$alert({
                type: 'warning',
                    text: !!action.customDeleteMsg ? action.customDeleteMsg: this.$t('GENERAL.ACTIONS.DELETE_MESSAGE'),
                    confirmButtonText: this.$t('GENERAL.YES'),
                    cancelButtonText: this.$t('GENERAL.NO'),
                    hasConfirmation: true,
                    confirmCallback: () => {
                        this.doRemove(data.id)
                        .then(() => {
                           this.$emit('updateProcess');
                        })
                    }
                });
            }
        },
        getSteps() {
            return this.stepList;
        },
    },
};
</script>
